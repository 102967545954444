/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DocumentState = "ARCHIVED" | "DEFAULT" | "%future added value";
export type DocumentInspectorColumnQueryVariables = {
    documentId: string;
    tenantId: string;
};
export type DocumentInspectorColumnQueryResponse = {
    readonly config: ReadonlyArray<{
        readonly id: string;
        readonly key: string;
        readonly value: unknown;
    } | null> | null;
    readonly node: {
        readonly id: string;
        readonly files?: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly contentLength: number;
                    readonly name: string;
                    readonly path: ReadonlyArray<string>;
                    readonly downloadUrl: string | null;
                } | null;
            } | null> | null;
        } | null;
        readonly title?: string;
        readonly state?: DocumentState;
        readonly description?: string | null;
        readonly hasUpdates?: boolean;
        readonly status?: {
            readonly id: string;
            readonly name: string;
            readonly identifier: string;
            readonly color: string;
            readonly textColor: string;
        };
        readonly props?: unknown | null;
    } | null;
    readonly tenant: {
        readonly id: string;
        readonly statuses?: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly name: string;
                    readonly identifier: string;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};
export type DocumentInspectorColumnQuery = {
    readonly response: DocumentInspectorColumnQueryResponse;
    readonly variables: DocumentInspectorColumnQueryVariables;
};



/*
query DocumentInspectorColumnQuery(
  $documentId: ID!
  $tenantId: ID!
) {
  config(tenantId: $tenantId, nodeId: $tenantId, keys: ["de.smartconex.vertragsgenerator.demo"]) {
    id
    key
    value
  }
  node(id: $documentId) {
    __typename
    id
    ... on Document {
      title
      state
      description
      hasUpdates
      status {
        id
        name
        identifier
        color
        textColor
      }
      props
    }
    ... on HasFiles {
      __isHasFiles: __typename
      files {
        edges {
          node {
            id
            contentLength
            name
            path
            downloadUrl
          }
        }
      }
    }
  }
  tenant: node(id: $tenantId) {
    __typename
    id
    ... on Tenant {
      statuses {
        edges {
          node {
            id
            name
            identifier
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "documentId"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "keys",
                "value": [
                    "de.smartconex.vertragsgenerator.demo"
                ]
            },
            {
                "kind": "Variable",
                "name": "nodeId",
                "variableName": "tenantId"
            },
            {
                "kind": "Variable",
                "name": "tenantId",
                "variableName": "tenantId"
            }
        ],
        "concreteType": "ConfigValue",
        "kind": "LinkedField",
        "name": "config",
        "plural": true,
        "selections": [
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v3 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "documentId"
        } as any
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "identifier",
        "storageKey": null
    } as any, v6 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasUpdates",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Status",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                    (v1 /*: any*/),
                    (v4 /*: any*/),
                    (v5 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "color",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "textColor",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "props",
                "storageKey": null
            }
        ],
        "type": "Document",
        "abstractKey": null
    } as any, v7 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "FileConnection",
                "kind": "LinkedField",
                "name": "files",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "FileEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "File",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v1 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "contentLength",
                                        "storageKey": null
                                    },
                                    (v4 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "path",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "downloadUrl",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "HasFiles",
        "abstractKey": "__isHasFiles"
    } as any, v8 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "tenantId"
        } as any
    ], v9 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "StatusConnection",
                "kind": "LinkedField",
                "name": "statuses",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "StatusEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Status",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v1 /*: any*/),
                                    (v4 /*: any*/),
                                    (v5 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Tenant",
        "abstractKey": null
    } as any, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DocumentInspectorColumnQuery",
            "selections": [
                (v2 /*: any*/),
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": "tenant",
                    "args": (v8 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v9 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DocumentInspectorColumnQuery",
            "selections": [
                (v2 /*: any*/),
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v10 /*: any*/),
                        (v1 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": "tenant",
                    "args": (v8 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v10 /*: any*/),
                        (v1 /*: any*/),
                        (v9 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "1204cf603dcb0e1e8721a0f72d75a94f",
            "id": null,
            "metadata": {},
            "name": "DocumentInspectorColumnQuery",
            "operationKind": "query",
            "text": "query DocumentInspectorColumnQuery(\n  $documentId: ID!\n  $tenantId: ID!\n) {\n  config(tenantId: $tenantId, nodeId: $tenantId, keys: [\"de.smartconex.vertragsgenerator.demo\"]) {\n    id\n    key\n    value\n  }\n  node(id: $documentId) {\n    __typename\n    id\n    ... on Document {\n      title\n      state\n      description\n      hasUpdates\n      status {\n        id\n        name\n        identifier\n        color\n        textColor\n      }\n      props\n    }\n    ... on HasFiles {\n      __isHasFiles: __typename\n      files {\n        edges {\n          node {\n            id\n            contentLength\n            name\n            path\n            downloadUrl\n          }\n        }\n      }\n    }\n  }\n  tenant: node(id: $tenantId) {\n    __typename\n    id\n    ... on Tenant {\n      statuses {\n        edges {\n          node {\n            id\n            name\n            identifier\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '45901e996815c2f323075ce932bf1eeb';
export default node;
