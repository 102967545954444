import {
  graphql,
  newId,
  useHistory,
  useLazyLoadQuery,
  useMutation,
} from "@workflows/runtime-web";
import {
  Button,
  Callout,
  Column,
  ColumnBody,
  ColumnFooter,
  ColumnHeader,
  ConfirmDialog,
  Link,
  NonIdealState,
  Skeleton,
  useDialog,
  useViewerTenant,
} from "@workflows/ui";
import { format } from "date-fns";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { DocumentUpdateDetailsColumnMutation } from "~/__graphql__/DocumentUpdateDetailsColumnMutation.graphql";
import { DocumentUpdateDetailsColumnQuery } from "~/__graphql__/DocumentUpdateDetailsColumnQuery.graphql";
import { routes } from "../routes";
import { ChangelogEntries } from "../templates/ChangelogEntries";

export interface DocumentUpdateDetailsColumnProps {
  documentId: string;
}

export function DocumentUpdateDetailsColumn({
  documentId,
}: DocumentUpdateDetailsColumnProps): JSX.Element {
  const { tenantId } = useViewerTenant(true);
  const { t } = useTranslation("de.smartconex.vertragsgenerator");

  const success = useDialog(false);
  const [state, setState] = React.useState<
    | { state: "error"; message: string }
    | { state: "success"; documentId: string }
    | null
  >(null);

  const data = useLazyLoadQuery<DocumentUpdateDetailsColumnQuery>(
    $DocumentUpdateDetailsColumn,
    { documentId }
  );

  const [migrateDocument, isPending] =
    useMutation<DocumentUpdateDetailsColumnMutation>(
      $DocumentUpdateDetailsColumnMutation
    );

  const handleUpdate = () => {
    if (!data.node || !data.node.update) {
      return;
    }

    migrateDocument({
      variables: {
        input: {
          clientMutationId: newId(),
          tenantId,
          updateId: data.node.update.id,
          title: `${data.node.title} (Version ${format(
            new Date(),
            "dd.MM.yyyy"
          )})`,
        },
      },
      onError(error) {
        console.error("Failed to migrate document.", error);
        setState({
          state: "error",
          message: t("DocumentUpdateDetailsColumn.errors.unspecified"),
        });
      },
      onCompleted(data) {
        if (data.migrateDocument?.errors) {
          console.error(
            "Failed to migrate document.",
            data.migrateDocument.errors
          );
          setState({
            state: "error",
            message: t("DocumentUpdateDetailsColumn.errors.unspecified"),
          });
        } else if (data.migrateDocument && data.migrateDocument.document) {
          const documentId = data.migrateDocument.document.id;
          setState({ state: "success", documentId });
          success.openDialog();
        }
      },
    });
  };

  return (
    <>
      <Column id="DocumentUpdateDetailsColumn" role="aside" background="muted">
        <ColumnHeader title={t("DocumentUpdateDetailsColumn.title")} />
        <ColumnBody scrollable inset>
          {state && state.state === "error" && (
            <Callout intent="critical">{state.message}</Callout>
          )}
          {data.node &&
            data.node.props &&
            (data.node.props as any).changelog && (
              <ChangelogEntries
                identifier={(data.node.props as any).changelog}
              />
            )}
        </ColumnBody>
        <ColumnFooter
          end={
            <Button
              intent="primary"
              onClick={handleUpdate}
              isLoading={isPending}
            >
              {t("DocumentUpdateDetailsColumn.updateDocument")}
            </Button>
          }
        />
      </Column>
      {state && state.state === "success" && (
        <ChangelogDetailsColumnSuccessDialog
          isOpen={success.isDialogOpen}
          onClose={success.closeDialog}
          documentId={state.documentId}
          templateId={data.node!.template!.id}
        />
      )}
    </>
  );
}

const $DocumentUpdateDetailsColumn = graphql`
  query DocumentUpdateDetailsColumnQuery($documentId: ID!) {
    node(id: $documentId) {
      id
      ... on Document {
        props
        title
        template {
          id
          title
        }
        update {
          id
          sourceVersion
          targetVersion
        }
      }
    }
  }
`;

const $DocumentUpdateDetailsColumnMutation = graphql`
  mutation DocumentUpdateDetailsColumnMutation($input: MigrateDocumentInput!) {
    migrateDocument(input: $input) {
      errors {
        code
        path
        message
      }
      document {
        id
        hasUpdates
        update {
          id
          sourceVersion
          targetVersion
        }
      }
    }
  }
`;

DocumentUpdateDetailsColumn.Skeleton =
  function DocumentUpdateDetailsColumnSkeleton(): JSX.Element {
    const { t } = useTranslation("de.smartconex.vertragsgenerator");

    return (
      <Column id="DocumentUpdateDetailsColumn" role="aside" background="muted">
        <ColumnHeader title={t("DocumentUpdateDetailsColumn.title")} />
        <ColumnBody scrollable inset>
          <div>
            <Skeleton width={54} className="mb--md" />
            <Skeleton width={24} className="mb--md" />
            <Skeleton width={44} className="mb--md" />
            <Skeleton width={14} className="mb--md" />
          </div>
        </ColumnBody>
      </Column>
    );
  };

DocumentUpdateDetailsColumn.Placeholder =
  function DocumentUpdateDetailsColumnSkeleton(): JSX.Element {
    const { t } = useTranslation("de.smartconex.vertragsgenerator");

    return (
      <Column id="DocumentUpdateDetailsColumn" role="aside" background="muted">
        <ColumnHeader title={t("DocumentUpdateDetailsColumn.title")} />
        <ColumnBody scrollable inset>
          <NonIdealState
            description={t(
              "DocumentUpdateDetailsColumn.emptyState.description"
            )}
          />
        </ColumnBody>
      </Column>
    );
  };

function ChangelogDetailsColumnSuccessDialog({
  isOpen,
  onClose,
  documentId,
  templateId,
}: {
  isOpen: boolean;
  onClose: () => void;
  documentId: string;
  templateId: string;
}) {
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const history = useHistory();
  const handleConfirm = (result: boolean) => {
    if (result) {
      history.push(routes["wizard.index"].build({ documentId }));
    } else {
      onClose();
    }
  };

  return (
    <ConfirmDialog
      cancelText=""
      confirmText={t("DocumentUpdateDetailsColumn.successState.reviewDocument")}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
      showIcon={false}
      size="xs"
      title={t("DocumentUpdateDetailsColumn.successState.title")}
      cancelable={false}
    >
      <Trans
        i18nKey="DocumentUpdateDetailsColumn.successState.description"
        t={t}
      >
        Your document was updated to a newer version and you should review the
        changes now. Make sure to check if there are new settings that apply to
        your document. You can view the full list of changes in the
        <Link
          underline
          href={routes["templates.index"].build({ templateId })}
          target="_blank"
        >
          templates section (opens in a new tab)
        </Link>
        .
      </Trans>
    </ConfirmDialog>
  );
}
