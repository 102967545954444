import { Stack, StackItem, Text } from "@workflows/ui";
import * as React from "react";

export interface ChangelogEntriesItemProps {
  title: React.ReactNode;
  version: React.ReactNode;
  children: React.ReactNode;
}

export function ChangelogEntriesItem({
  children,
  title,
  version,
}: ChangelogEntriesItemProps): JSX.Element {
  return (
    <Stack direction="vertical">
      <StackItem>
        <Text variant="heading">{title}</Text>
        <Text variant="bodySm">Version: {version}</Text>
      </StackItem>
      <StackItem>{children}</StackItem>
    </Stack>
  );
}
