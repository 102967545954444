/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateDocumentInput = {
    clientMutationId: string;
    description?: string | null;
    documentId: string;
    props?: unknown | null;
    statusId?: string | null;
    statusIdentifier?: string | null;
    tenantId: string;
    title?: string | null;
};
export type DocumentInspectorColumnMutationVariables = {
    input: UpdateDocumentInput;
};
export type DocumentInspectorColumnMutationResponse = {
    readonly updateDocument: {
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
        } | null> | null;
        readonly document: {
            readonly id: string;
            readonly title: string;
            readonly description: string | null;
            readonly status: {
                readonly id: string;
                readonly name: string;
                readonly identifier: string;
                readonly color: string;
                readonly textColor: string;
            };
        } | null;
    } | null;
};
export type DocumentInspectorColumnMutation = {
    readonly response: DocumentInspectorColumnMutationResponse;
    readonly variables: DocumentInspectorColumnMutationVariables;
};



/*
mutation DocumentInspectorColumnMutation(
  $input: UpdateDocumentInput!
) {
  updateDocument(input: $input) {
    errors {
      code
      path
      message
    }
    document {
      id
      title
      description
      status {
        id
        name
        identifier
        color
        textColor
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "UpdateDocumentPayload",
            "kind": "LinkedField",
            "name": "updateDocument",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "InputError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "path",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "document",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Status",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "identifier",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "color",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "textColor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DocumentInspectorColumnMutation",
            "selections": (v2 /*: any*/),
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DocumentInspectorColumnMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "b3027050f0ad8e70bda06414fe1c5793",
            "id": null,
            "metadata": {},
            "name": "DocumentInspectorColumnMutation",
            "operationKind": "mutation",
            "text": "mutation DocumentInspectorColumnMutation(\n  $input: UpdateDocumentInput!\n) {\n  updateDocument(input: $input) {\n    errors {\n      code\n      path\n      message\n    }\n    document {\n      id\n      title\n      description\n      status {\n        id\n        name\n        identifier\n        color\n        textColor\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '93cbe74d0c75f5ef90c9af7937b73192';
export default node;
