import { getEdgeNodes } from "@workflows/runtime-web";
import {
  Callout,
  DownloadButton,
  FileList,
  Form2,
  FormControl,
  FormInput,
  Label,
  Select,
  SelectProps,
  Stack,
  StackItem,
  TextArea,
  TextAreaProps,
  TextField,
  TextFieldProps,
  UseForm,
} from "@workflows/ui";
import { FileListStaticFile } from "@workflows/ui/lib/FileList/FileListStatic";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DocumentInspectorColumnQueryResponse } from "~/__graphql__/DocumentInspectorColumnQuery.graphql";

export interface DocumentInspectorProps {
  form: UseForm;
  document: NonNullable<DocumentInspectorColumnQueryResponse["node"]>;
  tenant: NonNullable<DocumentInspectorColumnQueryResponse["tenant"]>;
  config: NonNullable<DocumentInspectorColumnQueryResponse["config"]>;
}

export function DocumentInspector({
  document,
  tenant,
  config,
  form,
}: DocumentInspectorProps): JSX.Element {
  const { t } = useTranslation("de.smartconex.vertragsgenerator");

  const statuses = getEdgeNodes(tenant?.statuses || null).map((status) => ({
    label: status.name,
    value: status.identifier,
  }));

  const isDemo = (config || []).some((item) => item?.value == true);
  const fileNodes = getEdgeNodes(document.files || null);

  React.useEffect(() => {
    form.reset({
      title: document.title,
      description: document.description,
      status: document.status?.identifier,
    });
  }, [document, form]);

  return (
    <Form2
      name="DocumentInspectorForm"
      defaultValues={{
        title: document.title,
        description: document.description,
        status: document.status?.id,
      }}
    >
      <FormControl label={t("DocumentInspector.inputs.title")} name="title">
        <FormInput<TextFieldProps>
          autoFocus
          component={TextField}
          name="title"
          required
        />
      </FormControl>

      <FormControl
        label={t("DocumentInspector.inputs.description")}
        name="description"
      >
        <FormInput<TextAreaProps> component={TextArea} name="description" />
      </FormControl>
      <FormControl label={t("DocumentInspector.inputs.status")} name="status">
        <FormInput<SelectProps>
          id="status"
          component={Select}
          name="status"
          options={statuses}
        />
      </FormControl>

      <Stack direction="vertical" gap="xxs">
        <Label>{t("DocumentInspector.export.label")}</Label>
        <StackItem>
          <DownloadButton
            fileName=""
            url={`/endpoints/01F4KFKV6WD81ZGBP7ZTNSFCQS/${document.id}`}
            disabled={isDemo}
          >
            {t("DocumentInspector.export.docx")}
          </DownloadButton>
        </StackItem>
        {isDemo && (
          <StackItem>
            <Callout intent="default">
              {t("common.exportIsDisabledInDemo")}
            </Callout>
          </StackItem>
        )}
      </Stack>

      {fileNodes.length > 0 && (
        <Stack direction="vertical" gap="xxs">
          <Label>{t("DocumentInspector.attachments.label")}</Label>
          <FileList files={fileNodes as FileListStaticFile[]} />
        </Stack>
      )}
    </Form2>
  );
}
