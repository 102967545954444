/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DocumentUpdateDetailsColumnQueryVariables = {
    documentId: string;
};
export type DocumentUpdateDetailsColumnQueryResponse = {
    readonly node: {
        readonly id: string;
        readonly props?: unknown | null;
        readonly title?: string;
        readonly template?: {
            readonly id: string;
            readonly title: string;
        } | null;
        readonly update?: {
            readonly id: string;
            readonly sourceVersion: string;
            readonly targetVersion: string;
        } | null;
    } | null;
};
export type DocumentUpdateDetailsColumnQuery = {
    readonly response: DocumentUpdateDetailsColumnQueryResponse;
    readonly variables: DocumentUpdateDetailsColumnQueryVariables;
};



/*
query DocumentUpdateDetailsColumnQuery(
  $documentId: ID!
) {
  node(id: $documentId) {
    __typename
    id
    ... on Document {
      props
      title
      template {
        id
        title
      }
      update {
        id
        sourceVersion
        targetVersion
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "documentId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "documentId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any, v4 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "props",
                "storageKey": null
            },
            (v3 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "DocumentTemplate",
                "kind": "LinkedField",
                "name": "template",
                "plural": false,
                "selections": [
                    (v2 /*: any*/),
                    (v3 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "DocumentUpdate",
                "kind": "LinkedField",
                "name": "update",
                "plural": false,
                "selections": [
                    (v2 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sourceVersion",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "targetVersion",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Document",
        "abstractKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DocumentUpdateDetailsColumnQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DocumentUpdateDetailsColumnQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "72eb1685229255f5d3ee6c3fae860d97",
            "id": null,
            "metadata": {},
            "name": "DocumentUpdateDetailsColumnQuery",
            "operationKind": "query",
            "text": "query DocumentUpdateDetailsColumnQuery(\n  $documentId: ID!\n) {\n  node(id: $documentId) {\n    __typename\n    id\n    ... on Document {\n      props\n      title\n      template {\n        id\n        title\n      }\n      update {\n        id\n        sourceVersion\n        targetVersion\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'f7b993cc7786907a5d0192673ad03071';
export default node;
