/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DocumentsUpdatesListPaginationQueryVariables = {
    count?: number | null;
    cursor?: string | null;
    tenantId: string;
};
export type DocumentsUpdatesListPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"DocumentsUpdatesList_documents">;
};
export type DocumentsUpdatesListPaginationQuery = {
    readonly response: DocumentsUpdatesListPaginationQueryResponse;
    readonly variables: DocumentsUpdatesListPaginationQueryVariables;
};



/*
query DocumentsUpdatesListPaginationQuery(
  $count: Int = 100
  $cursor: String
  $tenantId: ID!
) {
  ...DocumentsUpdatesList_documents_Nd6Pz
}

fragment DocumentsUpdatesList_documents_Nd6Pz on RootQueryType {
  documents(tenantId: $tenantId, after: $cursor, first: $count, hasUpdates: true) {
    edges {
      cursor
      node {
        id
        title
        update {
          sourceVersion
          targetVersion
          id
        }
        template {
          id
          title
        }
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": 100,
            "kind": "LocalArgument",
            "name": "count"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any,
        {
            "kind": "Literal",
            "name": "hasUpdates",
            "value": true
        } as any,
        (v1 /*: any*/)
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DocumentsUpdatesListPaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        },
                        (v1 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "DocumentsUpdatesList_documents"
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DocumentsUpdatesListPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "DocumentConnection",
                    "kind": "LinkedField",
                    "name": "documents",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DocumentEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Document",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "DocumentUpdate",
                                            "kind": "LinkedField",
                                            "name": "update",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "sourceVersion",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "targetVersion",
                                                    "storageKey": null
                                                },
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "DocumentTemplate",
                                            "kind": "LinkedField",
                                            "name": "template",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v4 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "filters": [
                        "tenantId",
                        "hasUpdates"
                    ],
                    "handle": "connection",
                    "key": "DocumentsUpdatesList_documents",
                    "kind": "LinkedHandle",
                    "name": "documents"
                }
            ]
        },
        "params": {
            "cacheID": "620401a4c7ad1e013a35779391cb44e5",
            "id": null,
            "metadata": {},
            "name": "DocumentsUpdatesListPaginationQuery",
            "operationKind": "query",
            "text": "query DocumentsUpdatesListPaginationQuery(\n  $count: Int = 100\n  $cursor: String\n  $tenantId: ID!\n) {\n  ...DocumentsUpdatesList_documents_Nd6Pz\n}\n\nfragment DocumentsUpdatesList_documents_Nd6Pz on RootQueryType {\n  documents(tenantId: $tenantId, after: $cursor, first: $count, hasUpdates: true) {\n    edges {\n      cursor\n      node {\n        id\n        title\n        update {\n          sourceVersion\n          targetVersion\n          id\n        }\n        template {\n          id\n          title\n        }\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '80ac07d274593688ec479a09f3c75942';
export default node;
