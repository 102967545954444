import { Stack } from "@workflows/ui";
import * as React from "react";
import { Error500 } from "~/core/Error500";
import { ChangelogEntriesItem } from "./ChangelogEntriesItem";

export type Changelog = {
  title: string;
  version: string;
  content: React.ReactNode;
};

export interface ChangelogEntriesProps {
  identifier: string;
}

export function ChangelogEntries({
  identifier,
}: ChangelogEntriesProps): JSX.Element {
  const [error, setError] = React.useState<Error | null>(null);
  const [changelog, setChangelog] = React.useState<Changelog[] | null>(null);

  React.useEffect(() => {
    // eslint-disable-next-line node/no-unsupported-features/es-syntax
    import(
      /* webpackMode: "lazy" */
      `../wizards/${identifier}/changelog.tsx`
    )
      .then(({ changelog }) => setChangelog(changelog))
      .catch((err) => {
        console.error("Failed to load changelog", err);
        setError(err);
      });
  }, [identifier]);

  return (
    <Stack direction="vertical" gap="xl">
      {error && <Error500 />}

      {Array.isArray(changelog) &&
        changelog.map((changelog, idx) => (
          <ChangelogEntriesItem
            key={idx}
            title={changelog.title}
            version={changelog.version}
          >
            {changelog.content}
          </ChangelogEntriesItem>
        ))}
    </Stack>
  );
}
