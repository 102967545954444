import {
  ExtractNode,
  getEdgeNodes,
  graphql,
  useHistory,
  usePaginationFragment,
  useParams,
} from "@workflows/runtime-web";
import {
  Link,
  NonIdealState,
  Stack,
  StackItem,
  TableView,
  TemplatesEmptyIllustration,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  TemplatesList_templates,
  TemplatesList_templates$key,
} from "~/__graphql__/TemplatesList_templates.graphql";
import { routes } from "../routes";

export interface TemplatesListProps {
  isPending?: boolean;
  fragmentRef: TemplatesList_templates$key;
}

export type DocumentType = ExtractNode<
  NonNullable<TemplatesList_templates["templates"]>
>;

export function TemplatesList({
  isPending = false,
  fragmentRef,
}: TemplatesListProps): JSX.Element {
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const { templateId } = useParams<{ templateId?: string }>();
  const history = useHistory();

  const handleSelect = (selection: string[]) => {
    history.push(routes["templates.index"].build({ templateId: selection[0] }));
  };

  const { data } = usePaginationFragment($TemplatesList_templates, fragmentRef);
  const rows = React.useMemo(() => getEdgeNodes(data.templates), [data]);

  const columns = React.useMemo(
    () => [
      {
        id: "title",
        header: t("TemplatesList.columns.template"),
        width: "60%",
        openable: true,
        render: ({ value: document }: { value: DocumentType }) => {
          return (
            <Stack direction="horizontal" justify="between" fill>
              <Stack direction="vertical" gap="xxs">
                <StackItem>
                  <Link
                    to={routes["templates.index"].build({
                      templateId: document.id,
                    })}
                  >
                    {document.title}
                  </Link>
                </StackItem>
              </Stack>
            </Stack>
          );
        },
      },
    ],
    [t]
  );

  return (
    <>
      {rows !== null && rows.length ? (
        <TableView
          columns={columns}
          data={rows as Record<string, unknown>[]}
          isPending={isPending}
          onSelect={handleSelect}
          selection={templateId ? [templateId] : []}
          usePointer
        />
      ) : (
        <NonIdealState
          header={<TemplatesEmptyIllustration />}
          title={t("TemplatesList.emptyState.title")}
          description={t("TemplatesList.emptyState.description")}
        />
      )}
    </>
  );
}

// TODO: `count` is fixed
export const $TemplatesList_templates = graphql`
  fragment TemplatesList_templates on RootQueryType
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 100 }
    tenantId: { type: "ID!" }
  )
  @refetchable(queryName: "TemplatesListPaginationQuery") {
    templates: documentTemplates(
      tenantId: $tenantId
      after: $cursor
      first: $count
    ) @connection(key: "TemplatesList_templates") {
      edges {
        cursor
        node {
          id
          ... on DocumentTemplate {
            title
          }
        }
      }
    }
  }
`;
