/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TemplatesColumnQueryVariables = {
    tenantId: string;
};
export type TemplatesColumnQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"TemplatesList_templates">;
};
export type TemplatesColumnQuery = {
    readonly response: TemplatesColumnQueryResponse;
    readonly variables: TemplatesColumnQueryVariables;
};



/*
query TemplatesColumnQuery(
  $tenantId: ID!
) {
  ...TemplatesList_templates_1GYzvY
}

fragment TemplatesList_templates_1GYzvY on RootQueryType {
  templates: documentTemplates(tenantId: $tenantId, first: 100) {
    edges {
      cursor
      node {
        id
        title
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
    } as any, v2 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100
        } as any,
        (v1 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TemplatesColumnQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "TemplatesList_templates"
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TemplatesColumnQuery",
            "selections": [
                {
                    "alias": "templates",
                    "args": (v2 /*: any*/),
                    "concreteType": "DocumentTemplateConnection",
                    "kind": "LinkedField",
                    "name": "documentTemplates",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DocumentTemplateEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DocumentTemplate",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": "templates",
                    "args": (v2 /*: any*/),
                    "filters": [
                        "tenantId"
                    ],
                    "handle": "connection",
                    "key": "TemplatesList_templates",
                    "kind": "LinkedHandle",
                    "name": "documentTemplates"
                }
            ]
        },
        "params": {
            "cacheID": "29ab3fd4ff13fe36d8579f00aa9efb41",
            "id": null,
            "metadata": {},
            "name": "TemplatesColumnQuery",
            "operationKind": "query",
            "text": "query TemplatesColumnQuery(\n  $tenantId: ID!\n) {\n  ...TemplatesList_templates_1GYzvY\n}\n\nfragment TemplatesList_templates_1GYzvY on RootQueryType {\n  templates: documentTemplates(tenantId: $tenantId, first: 100) {\n    edges {\n      cursor\n      node {\n        id\n        title\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'ea1f983fc19da7238e1cc6673bb60383';
export default node;
