import {
  Column,
  ColumnBody,
  ColumnHeader,
  useViewerTenant,
} from "@workflows/ui";
import { useTranslation } from "react-i18next";
import { graphql, useLazyLoadQuery } from "react-relay/hooks";
import { TemplatesColumnQuery } from "~/__graphql__/TemplatesColumnQuery.graphql";
import { TemplatesList } from "./TemplatesList";
import * as React from "react";

export function TemplatesColumn(): JSX.Element {
  const { tenantId } = useViewerTenant(true);
  const { t } = useTranslation("de.smartconex.vertragsgenerator");

  const data = useLazyLoadQuery<TemplatesColumnQuery>($TemplatesColumnQuery, {
    tenantId,
  });

  return (
    <Column id="TemplatesColumn" role="main">
      <ColumnHeader title={t("TemplatesColumn.title")} />
      <ColumnBody>
        <TemplatesList fragmentRef={data} />
      </ColumnBody>
    </Column>
  );
}

const $TemplatesColumnQuery = graphql`
  query TemplatesColumnQuery($tenantId: ID!) {
    ...TemplatesList_templates @arguments(tenantId: $tenantId)
  }
`;
