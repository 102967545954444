import {
  ExtractNode,
  getEdgeNodes,
  graphql,
  useHistory,
  usePaginationFragment,
  useParams,
} from "@workflows/runtime-web";
import {
  NonIdealState,
  Stack,
  StackItem,
  TableView,
  TemplatesEmptyIllustration,
  Text,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  DocumentsUpdatesList_documents,
  DocumentsUpdatesList_documents$key,
} from "~/__graphql__/DocumentsUpdatesList_documents.graphql";
import { routes } from "../routes";

export interface DocumentsUpdatesListProps {
  isPending?: boolean;
  fragmentRef: DocumentsUpdatesList_documents$key;
}

export type DocumentType = ExtractNode<
  NonNullable<DocumentsUpdatesList_documents["documents"]>
>;

export function DocumentsUpdatesList({
  isPending = false,
  fragmentRef,
}: DocumentsUpdatesListProps): JSX.Element {
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const { documentId } = useParams<{ documentId?: string }>();
  const history = useHistory();

  const handleSelect = (selection: string[]) => {
    history.push(
      routes["documents.updates"].build({ documentId: selection[0] })
    );
  };

  const { data } = usePaginationFragment(
    $DocumentsUpdatesList_documents,
    fragmentRef
  );

  const rows = React.useMemo(() => getEdgeNodes(data.documents), [data]);

  const columns = React.useMemo(
    () => [
      {
        id: "document",
        header: t("DocumentsUpdatesList.columns.document"),
        width: "60%",
        openable: true,
        render: ({ value: document }: { value: DocumentType }) => {
          return (
            <Stack direction="horizontal" justify="between" fill>
              <Stack direction="vertical" gap="xxs">
                <StackItem>{document.title}</StackItem>
                {document.template && document.template.title && (
                  <StackItem>
                    <Text color="muted">{document.template.title}</Text>
                  </StackItem>
                )}
              </Stack>
            </Stack>
          );
        },
      },
      {
        id: "update",
        header: t("DocumentsUpdatesList.columns.update"),
        width: "40%",
        accessor: "update",
        render: ({ value: update }: { value: DocumentType["update"] }) => {
          return update
            ? `${update.sourceVersion} → ${update.targetVersion}`
            : null;
        },
      },
    ],
    [t]
  );

  return (
    <>
      {rows !== null && rows.length ? (
        <TableView
          columns={columns}
          data={rows as Record<string, unknown>[]}
          isPending={isPending}
          onSelect={handleSelect}
          selection={documentId ? [documentId] : []}
          usePointer
        />
      ) : (
        <NonIdealState
          header={<TemplatesEmptyIllustration />}
          title={t("DocumentsUpdatesList.emptyState.title")}
          description={t("DocumentsUpdatesList.emptyState.description")}
        />
      )}
    </>
  );
}

const $DocumentsUpdatesList_documents = graphql`
  fragment DocumentsUpdatesList_documents on RootQueryType
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 100 }
    tenantId: { type: "ID!" }
  )
  @refetchable(queryName: "DocumentsUpdatesListPaginationQuery") {
    documents(
      tenantId: $tenantId
      after: $cursor
      first: $count
      hasUpdates: true
    ) @connection(key: "DocumentsUpdatesList_documents") {
      edges {
        cursor
        node {
          id
          title
          update {
            sourceVersion
            targetVersion
          }
          template {
            id
            title
          }
        }
      }
    }
  }
`;
