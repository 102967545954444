/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DocumentsUpdatesList_documents = {
    readonly documents: {
        readonly edges: ReadonlyArray<{
            readonly cursor: string | null;
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly update: {
                    readonly sourceVersion: string;
                    readonly targetVersion: string;
                } | null;
                readonly template: {
                    readonly id: string;
                    readonly title: string;
                } | null;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "DocumentsUpdatesList_documents";
};
export type DocumentsUpdatesList_documents$data = DocumentsUpdatesList_documents;
export type DocumentsUpdatesList_documents$key = {
    readonly " $data"?: DocumentsUpdatesList_documents$data;
    readonly " $fragmentRefs": FragmentRefs<"DocumentsUpdatesList_documents">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        "documents"
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [
            {
                "defaultValue": 100,
                "kind": "LocalArgument",
                "name": "count"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cursor"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "tenantId"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "count",
                        "cursor": "cursor"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [],
                "operation": require('./DocumentsUpdatesListPaginationQuery.graphql.ts')
            }
        },
        "name": "DocumentsUpdatesList_documents",
        "selections": [
            {
                "alias": "documents",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "hasUpdates",
                        "value": true
                    },
                    {
                        "kind": "Variable",
                        "name": "tenantId",
                        "variableName": "tenantId"
                    }
                ],
                "concreteType": "DocumentConnection",
                "kind": "LinkedField",
                "name": "__DocumentsUpdatesList_documents_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "DocumentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Document",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v1 /*: any*/),
                                    (v2 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "DocumentUpdate",
                                        "kind": "LinkedField",
                                        "name": "update",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "sourceVersion",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "targetVersion",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "DocumentTemplate",
                                        "kind": "LinkedField",
                                        "name": "template",
                                        "plural": false,
                                        "selections": [
                                            (v1 /*: any*/),
                                            (v2 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "RootQueryType",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '80ac07d274593688ec479a09f3c75942';
export default node;
