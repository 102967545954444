import { RouteComponentProps } from "@workflows/runtime-web";
import { useDocumentTitle } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "~/core/ErrorBoundary";
import { DocumentsUpdatesColumn } from "./DocumentsUpdatesColumn";
import { DocumentUpdateDetailsColumn } from "./DocumentUpdateDetailsColumn";

export type DocumentsUpdatesRouteProps = RouteComponentProps<{
  documentId?: string;
}>;

export function DocumentsUpdatesRoute({
  match,
}: DocumentsUpdatesRouteProps): JSX.Element {
  const documentId = match.params.documentId;
  const { t } = useTranslation("de.smartconex.vertragsgenerator");

  useDocumentTitle(t("DocumentsUpdatesRoute.pageTitle"));

  return (
    <ErrorBoundary>
      <DocumentsUpdatesColumn />
      <React.Suspense fallback={<DocumentUpdateDetailsColumn.Skeleton />}>
        {documentId ? (
          <DocumentUpdateDetailsColumn documentId={documentId} />
        ) : (
          <DocumentUpdateDetailsColumn.Placeholder />
        )}
      </React.Suspense>
    </ErrorBoundary>
  );
}
