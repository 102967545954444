import { compileRoutes, Route } from "@workflows/runtime-web";
import React from "react";
import { CoreEntryPointRoute } from "~/core/CoreEntryPointRoute";
import {
  createStaticResource,
  EntryPointPreloadGlobals,
} from "~/core/entryPoints";
import { documentsColumnQuery } from "~/de.smartconex.vertragsgenerator/documents/DocumentsColumn";
import { DashboardRoute } from "./dashboard/DashboardRoute";
import { DocumentsRoute } from "./documents/DocumentsRoute";
import { DocumentsUpdatesRoute } from "./documents/DocumentsUpdatesRoute";
import { GlossaryRoute } from "./glossary/GlossaryRoute";
import { TemplatesRoute } from "./templates/TemplatesRoute";
import { WizardRoute } from "./wizard/WizardRoute";

export const routes = compileRoutes({
  "dashboard.index": () => "/",
  "documents.index": () => "/documents",
  "documents.updates": (_: { documentId: string }) =>
    "/documents/updates/:documentId?",
  "document.new": (_: { templateId?: string } = {}) =>
    "/documents/new/:templateId?",
  "wizard.index": (_: { documentId: string; slug?: string }) =>
    "/documents/:documentId/:slug?",
  "glossary.index": () => "/glossary/:slug?",
  "templates.index": (_: { templateId: string }) => "/templates/:templateId?",
});

const routeConfig = {
  "documents.index": {
    root: createStaticResource("DocumentsRoute", DocumentsRoute),
    getPreloadProps(config: {
      params: Record<string, never>;
      globals: EntryPointPreloadGlobals;
    }) {
      const variables = {
        tenantId: config.globals.tenantId,
      };

      return {
        queries: {
          documents: {
            parameters: documentsColumnQuery,
            variables,
          },
        },
      };
    },
  },
};

export const router = (
  <>
    <Route path={routes["document.new"].path} component={DocumentsRoute} />
    <Route
      path={routes["documents.updates"].path}
      component={DocumentsUpdatesRoute}
    />
    <Route path={routes["wizard.index"].path} component={WizardRoute} />
    <Route path={routes["glossary.index"].path} component={GlossaryRoute} />
    <Route
      path={routes["dashboard.index"].path}
      component={DashboardRoute}
      exact
    />

    <Route
      exact
      path={routes["templates.index"].path}
      component={TemplatesRoute}
    />

    <CoreEntryPointRoute
      exact
      path={routes["documents.index"].path}
      entrypoint={routeConfig["documents.index"]}
    />
  </>
);
