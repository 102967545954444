/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type MigrateDocumentInput = {
    clientMutationId: string;
    tenantId: string;
    title: string;
    updateId: string;
};
export type DocumentUpdateDetailsColumnMutationVariables = {
    input: MigrateDocumentInput;
};
export type DocumentUpdateDetailsColumnMutationResponse = {
    readonly migrateDocument: {
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
        } | null> | null;
        readonly document: {
            readonly id: string;
            readonly hasUpdates: boolean;
            readonly update: {
                readonly id: string;
                readonly sourceVersion: string;
                readonly targetVersion: string;
            } | null;
        } | null;
    } | null;
};
export type DocumentUpdateDetailsColumnMutation = {
    readonly response: DocumentUpdateDetailsColumnMutationResponse;
    readonly variables: DocumentUpdateDetailsColumnMutationVariables;
};



/*
mutation DocumentUpdateDetailsColumnMutation(
  $input: MigrateDocumentInput!
) {
  migrateDocument(input: $input) {
    errors {
      code
      path
      message
    }
    document {
      id
      hasUpdates
      update {
        id
        sourceVersion
        targetVersion
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "MigrateDocumentPayload",
            "kind": "LinkedField",
            "name": "migrateDocument",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "InputError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "path",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "document",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasUpdates",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DocumentUpdate",
                            "kind": "LinkedField",
                            "name": "update",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "sourceVersion",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "targetVersion",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DocumentUpdateDetailsColumnMutation",
            "selections": (v2 /*: any*/),
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DocumentUpdateDetailsColumnMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "bbc2cb0b34ea850ba5d783cb91f2a736",
            "id": null,
            "metadata": {},
            "name": "DocumentUpdateDetailsColumnMutation",
            "operationKind": "mutation",
            "text": "mutation DocumentUpdateDetailsColumnMutation(\n  $input: MigrateDocumentInput!\n) {\n  migrateDocument(input: $input) {\n    errors {\n      code\n      path\n      message\n    }\n    document {\n      id\n      hasUpdates\n      update {\n        id\n        sourceVersion\n        targetVersion\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'a3c2914b094c5d3dc232dde3d53473d6';
export default node;
