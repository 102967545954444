/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TemplatesListPaginationQueryVariables = {
    count?: number | null;
    cursor?: string | null;
    tenantId: string;
};
export type TemplatesListPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"TemplatesList_templates">;
};
export type TemplatesListPaginationQuery = {
    readonly response: TemplatesListPaginationQueryResponse;
    readonly variables: TemplatesListPaginationQueryVariables;
};



/*
query TemplatesListPaginationQuery(
  $count: Int = 100
  $cursor: String
  $tenantId: ID!
) {
  ...TemplatesList_templates_Nd6Pz
}

fragment TemplatesList_templates_Nd6Pz on RootQueryType {
  templates: documentTemplates(tenantId: $tenantId, after: $cursor, first: $count) {
    edges {
      cursor
      node {
        id
        title
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": 100,
            "kind": "LocalArgument",
            "name": "count"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any,
        (v1 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TemplatesListPaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        },
                        (v1 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "TemplatesList_templates"
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TemplatesListPaginationQuery",
            "selections": [
                {
                    "alias": "templates",
                    "args": (v2 /*: any*/),
                    "concreteType": "DocumentTemplateConnection",
                    "kind": "LinkedField",
                    "name": "documentTemplates",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DocumentTemplateEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DocumentTemplate",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": "templates",
                    "args": (v2 /*: any*/),
                    "filters": [
                        "tenantId"
                    ],
                    "handle": "connection",
                    "key": "TemplatesList_templates",
                    "kind": "LinkedHandle",
                    "name": "documentTemplates"
                }
            ]
        },
        "params": {
            "cacheID": "1e4f625bb7391e8660b3d61ac9477b47",
            "id": null,
            "metadata": {},
            "name": "TemplatesListPaginationQuery",
            "operationKind": "query",
            "text": "query TemplatesListPaginationQuery(\n  $count: Int = 100\n  $cursor: String\n  $tenantId: ID!\n) {\n  ...TemplatesList_templates_Nd6Pz\n}\n\nfragment TemplatesList_templates_Nd6Pz on RootQueryType {\n  templates: documentTemplates(tenantId: $tenantId, after: $cursor, first: $count) {\n    edges {\n      cursor\n      node {\n        id\n        title\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'bdd10078504e868a7fa76db7bf88753f';
export default node;
