import { graphql, useLazyLoadQuery } from "@workflows/runtime-web";
import {
  Column,
  ColumnBody,
  ColumnHeader,
  NonIdealState,
  Skeleton,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Error500 } from "~/core/Error500";
import { TemplateDetailsColumnQuery } from "~/__graphql__/TemplateDetailsColumnQuery.graphql";
import { ChangelogEntries } from "./ChangelogEntries";

export interface TemplateDetailsColumnProps {
  templateId: string;
}

export function TemplateDetailsColumn({
  templateId,
}: TemplateDetailsColumnProps): JSX.Element {
  const { t } = useTranslation("de.smartconex.vertragsgenerator");

  const data = useLazyLoadQuery<TemplateDetailsColumnQuery>(
    $TemplateDetailsColumn,
    { templateId }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props = data.node?.props as any;
  const changelog = props?.changelog;

  return (
    <>
      <Column id="TemplateDetailsColumn" role="aside" background="muted">
        <ColumnHeader title={t("TemplateDetailsColumn.title")} />
        <ColumnBody scrollable inset>
          {!changelog ? (
            <Error500 />
          ) : (
            <ChangelogEntries identifier={changelog} />
          )}
        </ColumnBody>
      </Column>
    </>
  );
}

const $TemplateDetailsColumn = graphql`
  query TemplateDetailsColumnQuery($templateId: ID!) {
    node(id: $templateId) {
      id
      ... on DocumentTemplate {
        props
      }
    }
  }
`;

TemplateDetailsColumn.Skeleton =
  function TemplateDetailsColumnSkeleton(): JSX.Element {
    const { t } = useTranslation("de.smartconex.vertragsgenerator");

    return (
      <>
        <Column id="TemplateDetailsColumn" role="aside" background="muted">
          <ColumnHeader title={t("TemplateDetailsColumn.title")} />
          <ColumnBody scrollable inset>
            <div>
              <Skeleton width={54} className="mb--md" />
              <Skeleton width={24} className="mb--md" />
              <Skeleton width={44} className="mb--md" />
              <Skeleton width={14} className="mb--md" />
            </div>
          </ColumnBody>
        </Column>
      </>
    );
  };

TemplateDetailsColumn.Placeholder =
  function TemplateDetailsColumnSkeleton(): JSX.Element {
    const { t } = useTranslation("de.smartconex.vertragsgenerator");

    return (
      <>
        <Column id="TemplateDetailsColumn" role="aside" background="muted">
          <ColumnHeader title={t("TemplateDetailsColumn.title")} />
          <ColumnBody scrollable inset>
            <NonIdealState
              description={t("TemplateDetailsColumn.emptyState.description")}
            />
          </ColumnBody>
        </Column>
      </>
    );
  };
