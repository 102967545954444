import { graphql, useHistory, useLazyLoadQuery } from "@workflows/runtime-web";
import {
  Column,
  ColumnBody,
  ColumnHeader,
  useViewerTenant,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DocumentsUpdatesColumnQuery } from "~/__graphql__/DocumentsUpdatesColumnQuery.graphql";
import { routes } from "../routes";
import { DocumentsUpdatesList } from "./DocumentsUpdatesList";

export function DocumentsUpdatesColumn(): JSX.Element {
  const { tenantId } = useViewerTenant(true);
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const history = useHistory();

  const data = useLazyLoadQuery<DocumentsUpdatesColumnQuery>(
    $DocumentsUpdatesColumnQuery,
    {
      tenantId,
    },
    {
      fetchPolicy: "store-and-network",
    }
  );

  return (
    <Column id="DocumentsUpdatesColumn" role="main">
      <ColumnHeader
        title={t("DocumentsUpdatesColumn.title")}
        back
        onBack={() => {
          history.push(routes["documents.index"].path);
        }}
      />
      <ColumnBody>
        <DocumentsUpdatesList fragmentRef={data} />
      </ColumnBody>
    </Column>
  );
}

const $DocumentsUpdatesColumnQuery = graphql`
  query DocumentsUpdatesColumnQuery($tenantId: ID!) {
    ...DocumentsUpdatesList_documents @arguments(tenantId: $tenantId)
  }
`;
