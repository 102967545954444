import { RouteComponentProps } from "@workflows/runtime-web";
import { useDocumentTitle } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "~/core/ErrorBoundary";
import { TemplateDetailsColumn } from "./TemplateDetailsColumn";
import { TemplatesColumn } from "./TemplatesColumn";

export type TemplatesRouteProps = RouteComponentProps<{
  templateId?: string;
}>;

export function TemplatesRoute({ match }: TemplatesRouteProps): JSX.Element {
  const templateId = match.params.templateId;
  const { t } = useTranslation("de.smartconex.vertragsgenerator");

  useDocumentTitle(t("TemplatesRoute.pageTitle"));

  return (
    <ErrorBoundary>
      <TemplatesColumn />
      <React.Suspense fallback={<TemplateDetailsColumn.Skeleton />}>
        {templateId ? (
          <TemplateDetailsColumn templateId={templateId} />
        ) : (
          <TemplateDetailsColumn.Placeholder />
        )}
      </React.Suspense>
    </ErrorBoundary>
  );
}
